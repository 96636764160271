<template>
  <div>
    <button class="btn btn-large btn-success" :class="{'btn-danger': (netStatus == 'Off') }" @click="toggle">Kids Network {{ netStatus }}</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    netStatus() {
        return Object.hasOwn(this.rule, "disabled") ? "On" : "Off";
    }
  },
  methods: {
    toggle() {
        if (Object.hasOwn(this.rule, "disabled")) {
            this.rule.disabled = false;
        } else {
            this.rule.disabled = true;
        }
        axios.put('https://eclipse.dancingvixen.com/api/v1/firewall/rule', this.rule)
            .then(() => {
                this.applyRules();
            });
    },
    readRules() {
        axios.get('https://eclipse.dancingvixen.com/api/v1/firewall/rule')
            .then((response) => {
                // handle success
                let data = response.data.data;
                for (const d of data) {
                    if (d.descr == "KidsRule") {
                        this.rule = d;
                        console.log(d);
                        break;
                    }
                }
            });
    },
    applyRules() {
        axios.post('https://eclipse.dancingvixen.com/api/v1/firewall/apply')
            .then(() => {
                this.readRules();
            });
    }
  },
  data() {
    return {
        rule: {}
    }
  },
  mounted() {
    axios.defaults.auth = { username: "admin", password: "digit8for3" };
    this.readRules();
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
