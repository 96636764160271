<template>
    <div>
        <p>Netkill allows you to kill the Kids network entirely. No devices attached to Eclipse-Kids will be able to communicate with the outside world.</p>
        <Netkill msg="Welcome to Your Vue.js App"/>
    </div>
</template>

<script>
import Netkill from './components/Netkill.vue'

export default {
  name: 'App',
  components: {
    Netkill
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
